import React from 'react'
import {
  Center,
  Text,
  Box,
  Grid,
  FormControl,
  GridItem,
  VStack,
  HStack,
  Flex,
} from '@chakra-ui/react'
import { Controller, useForm } from 'react-hook-form'
import { CardContent } from '../../../../../../Components/Card/card'
import { SwitchInputComponent } from '../../../switchInput'
import { totalNonWebEnabledPage } from '../..'

function PainCareSpecialists({ formView }) {
  const {
    // handleSubmit,
    // reset,
    // register,
    // errors,
    control,
    // setValue,
    // watch,
    // clearErrors,
  } = useForm()
  return (
    <CardContent>
      <Box>
        <Center fontWeight="bold" fontSize="20px">
          {`PAIN CARE SPECIALISTS OF OREGON, LLC HIPAA CONSENT`}
        </Center>
        <Text pt="40px">
          {`By signing this Consent form, you give Pain Care Specialists of Oregon, LLC, (PCSO) permission to use and disclose protected
   health information about you for treatment, payment, and healthcare operation except for any restrictions specified in the
   'Form to Request Restrictions'. Protected health information is individually identifiable information created or received by the
   practice, including demographic data, information relating to you physical or mental health, to provision of healthcare services
   to you, and to the collection of payment for providing healthcare services to you.`}
        </Text>
        <Text pt="15px">
          {`If the information to be disclosed contains any of the type of records or information for drug/alcohol diagnosis, treatment, or
   referral information, mental health information, genetic testing information, and HIV/AIDS information, additional laws relating
   to the use and disclosure of the information may apply. As the patient, you have the right to request restrictions in the use of
   your protected health information and to request change in certain policies used within the office concerning your PHI. However,
   we are not obligated to alter internal policies to conform to your request.`}
        </Text>
        <Text pt="15px">
          {`The PCSO Notice of Privacy Practices provides information about how PCSO may use and disclose protected health information
   about you. A copy of this notice of Privacy Practices is available to you for review prior to you signing the Consent Form. As
   referenced in the Notice, the terms of the Notice of Privacy Practice may be changed periodically. Copies of the most current
   Notice will be available at your request upon check-in for you appointment or by accessing the practice's website at www.paincareoregon.com. Should you have any questions or concerns about the handling of your protected health information,
   you may contact the PCSO Privacy Officer at (503) 371-1010.`}
        </Text>
        <Text pt="15px">
          {`You have the right to request restriction on the use or disclosure of you protected health information for treatment, payment,
   or healthcare operations. If you request for restriction is accepted, then the practice is bound to honor that agreement.`}
        </Text>
        <Text pt="15px">
          {`With this consent, PCSO may call your home or alternative location and leave a message or voicemail or with an individual in
   reference to any items that assist the practice in fulfilling TOP, such as appointment reminders, insurance items, and any call
   pertaining to your clinical care, including test results. With this consent, PCSO may mail to your home or alternative location any
   items that assist the practice in fulfilling TPO, such as appointment reminder cards and patient statements as long as they are
   marked 'Personal and Confidential'.`}
        </Text>
        <Text pt="15px">
          {`To the extent available, PCSO may attempt to electronically obtain your prescription medication history through your insurance
   provider, and or prescription benefits service. By signing this Consent form, you consent to any electronic download of said
   information which may be useful to your treatment.`}
        </Text>
        <Text pt="15px">
          {`If you elect not to sign this Consent Form, PCSO has the right to refuse you treatment unless a licensed healthcare professional
   has determined that you require emergency treatment, or the physician is required by law to treat you. The practice is required
   to document any circumstances in which treatment is provided without your consent; a copy of this documentation would be
   available to you.`}
        </Text>
        <Text pt="15px">
          {`You have the right to revoke this consent in writing unless disclosure have been made based upon your prior consent. To
   request your revocation, you may use the 'Authorization for Release of Information Form' or you may submit a letter to the practice.`}
        </Text>
        <Text pt="15px">
          {`I acknowledge that Pain Care Specialists of Oregon, LLC has offered a copy of the NOPP, Notice of Privacy Practices, which
   includes the updated HIPAA OMNIBUS rule.`}
        </Text>
        <Grid
          templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(2, 1fr)' }}
          gap={0}
          h="max-content"
          alignItems="start"
        >
          <Controller
            render={props => (
              <FormControl>
                <GridItem
                  onChange={props.onChange}
                  value={props.value}
                  onBlur={props.onBlur}
                  w="100%"
                >
                  <VStack spacing="0px">
                    <Center h={{ base: '80px' }}>
                      <Text fontWeight="bold" fontSize="15" textAlign="center">
                        Signature of Patient or Legel Representative
                      </Text>
                    </Center>
                    <HStack h="40px" w="100%" flexDirection="row">
                      <Center w="100%">
                        <Flex ml="50px">
                          <SwitchInputComponent
                            formView={formView.signaturePatient || ''}
                          />
                        </Flex>
                      </Center>
                    </HStack>
                  </VStack>
                  <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
                </GridItem>
                <Center></Center>
              </FormControl>
            )}
            name="signatureConsent"
            control={control}
            defaultValue=""
          />
          <GridItem w="100%">
            <VStack spacing="0px">
              <Center h={{ base: '80px' }}>
                <Text fontWeight="bold" fontSize="15">
                  Date
                </Text>
              </Center>
              <Center h="40px">
                <Text>
                  {new Date().toLocaleDateString('en-US', { hour12: false })}
                </Text>
              </Center>
            </VStack>
            <GridItem colSpan={2} h="0.1rem" bg="gray.300"></GridItem>
          </GridItem>
        </Grid>
      </Box>
      <Center pt="2rem">{`12/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { PainCareSpecialists }
