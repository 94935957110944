import { Center, Text, Box } from '@chakra-ui/react'
import React from 'react'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalWebEnablePages } from '../../config'

function LawEnforcement({ formView }) {
  return (
    <CardContent>
      <Box>
        <Text>
          <span style={{ fontWeight: 'bold' }}>{`Law Enforcement. `}</span>
          {`We may release PHI if asked to do so by a law enforcement official:`}
        </Text>
        <Text pt="8px" pl="15px">
          {`• Regarding a crime victim in certain situations, if we are unable to obtain the person's agreement Concerning a death, we believe has resulted from criminal conduct`}
        </Text>
        <Text pl="15px">{`• Regarding criminal conduct at our offices`}</Text>
        <Text pl="15px">{`• In response to a warrant, summons, court, order, subpoena or similar legal process`}</Text>
        <Text pl="15px">{`• To identify/locate a suspect, material witness, fugitive or a missing person`}</Text>
        <Text pl="15px">{`• In an emergency, to report a crime (including the location or victim (s) of the crime or the description, identity or location of the perpetrator)`}</Text>
        <Text pt="15px">
          <span
            style={{ fontWeight: 'bold' }}
          >{`Serious Threats to Health or Safety. `}</span>
          {`Our practice may use and disclose your PHI when necessary to reduce or prevent a serious threat to your health and safety or health and safety of another individual or the public. Under these circumstances, we will only make disclosures to a person or organization able to help prevent the threat.`}
        </Text>
        <Text pt="15px">
          <span style={{ fontWeight: 'bold' }}>{`National Security. `}</span>
          {`Our practice may disclose your PHI to federal officials for intelligence and national security activities authorized by law. We also may disclose your PHI to federal officials in order to protect the President, other officials or foreign heads of state, or to conduct investigations.`}
        </Text>
        <Text pt="15px" fontWeight="bold">
          {`YOUR RIGHTS REGARDING YOUR PHI`}
        </Text>
        <Text>{`You have the following rights regarding the PHI that we maintain:`}</Text>
        <Text pt="15px">
          <span
            style={{ fontWeight: 'bold' }}
          >{`Confidential Communications. `}</span>
          {`You have the right to request that our practice communicate with you about your health and related issues in a particular manner or at a certain location. For example, you may ask that we contact you at home, rather than work, or to send communications in a sealed envelope instead of a postcard. You may be asked to pay for additional costs incurred to comply with your request. In order to request a type of confidential communication, you must make written request to our Privacy Officer specifying the requested method of contact, or the location where you wish to be contacted. Our practice will accommodate reasonable requests. You do not need to give a reason for your request.`}
        </Text>
        <Text pt="15px">
          <span
            style={{ fontWeight: 'bold' }}
          >{`Requesting Restrictions. `}</span>
          {`You have the right to request a restriction in our use or disclosure of your PHI for treatment, payment, or health care operations. Additionally, you have the right to request that we restrict our disclosure of your PHI only to certain individuals involved in your care or the payment of your care, such as family members and friends. You may request to not have trainees or others involved in your care. We are not required to agree to your request; however, if we do agree, we are bound by our agreement except when otherwise required by law in emergencies, or when the infor mation is necessary to treat you. In order to request a restriction  in our use or disclosure of your PHI, you must make your request in writing to our Privacy Officer. Your request must describe in a clear concise fashion:`}
        </Text>
        <Text pt="8px" pl="15px">
          {`• The information you wish restricted`}
        </Text>
        <Text pl="15px">{`• Whether you are requesting to limit our practice's use, disclosure or both; and`}</Text>
        <Text pl="15px">{`• To whom you want the limits to apply`}</Text>
        <Text pt="15px">
          <span style={{ fontWeight: 'bold' }}>{`Inspection Copies.`}</span>
          {`You have the right to inspect and obtain a copy of the PHI that may be used to make decisions about you, including patient medical records and billing records, but not including patient medical records and billing records, but not including psychotherapy notes. You must submit your request in writing to Privacy Officer /Office Manager or designee, 2480 Liberty St NE, Ste 180, Salem, OR 97301 (503) 371-1010 in order to inspect and or/obtain a copy of your PHI. Our practice may charge a fee for the costs of copying associated with your request.`}
        </Text>
      </Box>
      <Center pt="2rem">{`3/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}

export { LawEnforcement }
