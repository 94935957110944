import React from 'react'
import { Center, Box, Text } from '@chakra-ui/react'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalNonWebEnabledPage } from '../..'

function System({ formView }) {
  return (
    <CardContent>
      <Box>
        <Text>
          <span style={{ fontWeight: 'bold' }}>{`system. `}</span>
          {`In order to obtain an accounting you must submit your request in writing to the Privacy Officer 2480 Liberty St NE, Ste 180, Salem, OR 97301. All requests for "accounting of disclosures" must state a time period. Our practice will notify you of the costs involved with additional requests, and you may withdraw your request before you incur any costs. `}
        </Text>
        <Text pt="15px">
          <span style={{ fontWeight: 'bold' }}>
            {`Right to Paper Copy of This Notice. `}
          </span>
          {`You are entitled to receive a paper copy of our Notice of Privacy Practices. You will be offered a copy on your first visit to the practice. You may ask us to give you a copy of this notice at any time. To obtain a paper copy of this notice, contact our Privacy Officer at (S03) 371-1010.`}
        </Text>
        <Text pt="15px">
          <span style={{ fontWeight: 'bold' }}>
            {`Right to File a Complaint. `}
          </span>
          {`If you believe your privacy rights have been violated, you may file a complaint with our practice or with our Secretary of the Department of Health and Human Service Office of Civil Rights, 500 Summer St NE, Salem, Oregon 97301, (503) 945-5944. All complaints must be submitted in writing. You will not be penalized for filing a complaint.`}
        </Text>
        <Text pt="15px">
          <span style={{ fontWeight: 'bold' }}>
            {`Right to Provide and Authorization for Other Uses and Disclosures. `}
          </span>
          {`Our practice will obtain your written authorization for uses and disclosures that are not covered by this notice or permitted by law, such as for research or marketing. Any authorization you provide to us regarding the use and disclosure of your PHI may be revoked at any time in writing. After you revoke your PHI for the reasons described in the authorization, we are required to retain records for your care.`}
        </Text>
      </Box>
      <Center pt="2rem">{`11/${totalNonWebEnabledPage}`}</Center>
    </CardContent>
  )
}

export { System }
