import { Center, Text } from '@chakra-ui/react'
import React from 'react'
import { CardContent } from '../../../../../../Components/Card/card'
import { totalWebEnablePages } from '../../config'

function NoticePrivacyPractices({ formView }) {
  return (
    <CardContent>
      <Center
        fontWeight="bold"
        fontSize={{ base: '18px', sm: '18px', md: '20px' }}
      >
        NOTICE OF PRIVACY PRACTICES
      </Center>
      <Text pt={{ base: '20px', sm: '40px' }} fontWeight="bold">
        {`THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU (AS A PATIENT
        OF THIS PRACTICE) MAY BE USED AND DISCLOSED, AND HOW YOU CAN GET ACCESS
        TO THIS INFORMATION. PLEASE REVIEW THIS NOTICE CAREFULLY.`}
      </Text>
      <Text pt="15px" fontWeight="bold">
        OUR COMMITMENT TO YOUR PRIVACY
      </Text>
      <Text>
        {`Our practice is dedicated to maintaining the privacy of your protected health information (PHI). In conducting our busine ss, we will create records regarding you and the treatment and services we provide to you. We are required by law to maintain the confidentiality of your PHI. We also are required by law to provide you with this notice of our legal duties and the privacy practices that we maintain in our practice concerning PHI. By federal and state law, we must follow the terms of the notice of privacy practices that we have in effect at the time. We realize that these laws are complicated, but we must provide you with the following important information:`}{' '}
      </Text>
      <Text pt="15px" fontWeight="normal">
        {`HOW WE MAY USE AND DISCLOSE YOUR PHI `}
      </Text>
      <Text>
        {`The terms of this notice apply to all records containing your PHI that are created or retained by our practice. We reserve the right to revise or amend this Notice of Privacy Practices. Any revision or amendment to this notice will be effective for all of your records that our practice maintained or created in the past and for any of your records th at we may create or maintain in the future. Our practice will post a copy of our current Notice in our office in a visible location at all times, and you may request a copy of our most current Notice at any time.`}
      </Text>
      <Text pt="15px" fontWeight="bold">
        {`IF YOU HAVE QUESTIONS ABOUT THIS NOTICE, PLEASE CONTACT:`}
      </Text>
      <Text>
        {`Privacy Officer/Practice Administrator or designee Pain Care Specialists of Oregon, LLC (503) 371-1010 `}
      </Text>
      <Text pt="15px" fontWeight="bold">
        {`WE MAY USE AND DISCLOSE YOUR PROTECTED HEALTH INFORMATION (PHI) IN THE FOLLOWING WAYS:`}
      </Text>
      <Text>{`The following categories describe the different ways in which we may use and disclose your PHI`}</Text>
      <Text pt="15px">
        <span style={{ fontWeight: 'bold' }}>{`Treatment.`}</span>
        {` Our practice may use your PHI to treat you. For example, we may ask you to have laboratory tests (such as blood or urine tests), and we may ask you to use the results to help us reach a diagnosis. We might use your PHI in order to write a prescription for you, or we might disclose your PHI to a Pharmacy when we order a prescription for you. Many of the people who work for our practice-including, but not limited to, our doctors and nurses-may use or disclose your PHI to others who may assist in your case, such as your friends or family members involved in your care.`}
      </Text>
      <Text pt="15px">
        <span style={{ fontWeight: 'bold' }}>{`Payment.`}</span>
        {` Our practices may use and disclose your PHI in order to bill and collect payment for the services and items you may receive from us. For example, we may contact your health insurer to certify that you are eligible for benefits (and for what range of benefits) and we may provide your insurer with details regarding your treatment to determine if your insurer will cover or pay for your treatment. We also may use and diagnose your PHI to obtain payment from third parties that may be responsible for such costs, such as friends or family members. Also we may use your PHI to bill you directly for services and items.`}
      </Text>
      <Text pt="15px">
        <span style={{ fontWeight: 'bold' }}>{`Heath Care Operations.`}</span>
        {` Our practice may use and disclose your PHI to operate our business. As an example of the ways in which we may use and disclose your information for our operations, our practice may use your PHI to evaluate the quality of care you received from us to conduct cost-management and business planning activities, or to train new health care workers.`}
      </Text>
      <Center pt="2rem">{`1/${totalWebEnablePages}`}</Center>
    </CardContent>
  )
}

export { NoticePrivacyPractices }
